// extracted by mini-css-extract-plugin
export var bgGrey100 = "GoogleReviewSlider-module--bg-grey-100--8ec00";
export var bgGrey150 = "GoogleReviewSlider-module--bg-grey-150--0993a";
export var bgGrey200 = "GoogleReviewSlider-module--bg-grey-200--2f29b";
export var bgGrey300 = "GoogleReviewSlider-module--bg-grey-300--d5f6f";
export var bgGrey400 = "GoogleReviewSlider-module--bg-grey-400--910a0";
export var bgGrey500 = "GoogleReviewSlider-module--bg-grey-500--512fb";
export var bgGrey600 = "GoogleReviewSlider-module--bg-grey-600--807e6";
export var bgGrey700 = "GoogleReviewSlider-module--bg-grey-700--da01a";
export var bgGrey800 = "GoogleReviewSlider-module--bg-grey-800--5a004";
export var bgGrey900 = "GoogleReviewSlider-module--bg-grey-900--b042b";
export var googleLogo = "GoogleReviewSlider-module--googleLogo--5e4c9";
export var googleLogoContainer = "GoogleReviewSlider-module--googleLogoContainer--8ca9d";
export var googleLogoLink = "GoogleReviewSlider-module--googleLogoLink--1d7b5";
export var innerWrapper = "GoogleReviewSlider-module--innerWrapper--25a23";
export var marginHack = "GoogleReviewSlider-module--marginHack--be920";
export var modalStars = "GoogleReviewSlider-module--modalStars--f7379";
export var nextArrow = "GoogleReviewSlider-module--nextArrow--7cf01";
export var rated1 = "GoogleReviewSlider-module--rated1--17a0c";
export var rated2 = "GoogleReviewSlider-module--rated2--e373b";
export var rated3 = "GoogleReviewSlider-module--rated3--ae556";
export var rated4 = "GoogleReviewSlider-module--rated4--62007";
export var rated5 = "GoogleReviewSlider-module--rated5--22c9b";
export var rating = "GoogleReviewSlider-module--rating--85489";
export var ratingScale = "GoogleReviewSlider-module--ratingScale--9fa62";
export var section = "GoogleReviewSlider-module--section--220d1";
export var sliderArrowBkgd = "GoogleReviewSlider-module--sliderArrowBkgd--41087";
export var stars = "GoogleReviewSlider-module--stars--555fe";
export var textGrey100 = "GoogleReviewSlider-module--text-grey-100--61a97";
export var textGrey150 = "GoogleReviewSlider-module--text-grey-150--a0828";
export var textGrey200 = "GoogleReviewSlider-module--text-grey-200--4fc1f";
export var textGrey300 = "GoogleReviewSlider-module--text-grey-300--64bad";
export var textGrey400 = "GoogleReviewSlider-module--text-grey-400--fbc54";
export var textGrey500 = "GoogleReviewSlider-module--text-grey-500--11e73";
export var textGrey600 = "GoogleReviewSlider-module--text-grey-600--ce782";
export var textGrey700 = "GoogleReviewSlider-module--text-grey-700--82623";
export var textGrey800 = "GoogleReviewSlider-module--text-grey-800--3cacf";
export var textGrey900 = "GoogleReviewSlider-module--text-grey-900--6478c";
export var wrapper = "GoogleReviewSlider-module--wrapper--80002";
import { graphql, useStaticQuery } from "gatsby"
import React, { useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

import { StaticImage } from "gatsby-plugin-image"
import { ModalPortal, ToggleModal } from "../portals/Modal.portal"
import { Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import * as style from "./GoogleReviewSlider.module.scss"

const GOOGLE_REVIEW_QUERY = graphql`
  query GOOGLE_REVIEW {
    allGooglePlacesPlace {
      nodes {
        googlePlacesId
        rating
        user_ratings_total
      }
    }
    allGooglePlacesReview(sort: { order: DESC, fields: time }) {
      nodes {
        author_name
        text
        rating
        relative_time_description
      }
    }
  }
`

const GoogleInfo = ({ className }) => (
  <div className={`${style.googleLogoContainer} ${className}`}>
    <a
      className={style.googleLogoLink}
      href={process.env.GOOGLE_REVIEWS_URL}
      target="_blank"
      rel="noopener noreferrer">
      <StaticImage
        src="../images/logo-google-reviews.png"
        alt="Google Reviews logo"
        placeholder="none"
        width={100}
      />
    </a>
    <p className="ml-3 mb-0 text-greyed">
      Review provided by Google. ©2021 Google LLC All rights reserved. Google
      and the Google logo are registered trademarks of Google LLC.
    </p>
  </div>
)

const GoogleReviewSlider = props => {
  const data = useStaticQuery(GOOGLE_REVIEW_QUERY)
  const googlePlacesPlace = data.allGooglePlacesPlace.nodes.filter(
    review => review.googlePlacesId === process.env.GOOGLE_PLACE_ID
  )
  const { rating } = googlePlacesPlace[0]
  const { nodes: reviews } = data.allGooglePlacesReview

  if (typeof window !== "undefined") {
    const root = document.querySelector(":root")
    root.style.setProperty("--google-rating", rating)
  }

  const slider = useRef(null)
  const slidesToShow = reviews.length > 2 ? 3 : 1

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <section className={style.section}>
      <div className={style.wrapper}>
        <div className={style.innerWrapper}>
          <Row className={"bg-lighter py-2 m-0 mb-3"}>
            <Col className="d-flex flex-column align-items-center justify-content-center p-0 m-0">
              <div className="d-flex align-items-center text-greyed">
                <span className={style.rating}>{rating.toFixed(1)}</span>{" "}
                <span className={style.ratingScale}>
                  out of
                  <br /> 5 stars
                </span>
              </div>
              <div
                className={style.stars}
                aria-label={`${process.env.ORG_OFFICIAL_NAME} is rated ${rating} out of 5 on Google Reviews.`}
              />
            </Col>
          </Row>

          {reviews.length > slidesToShow && (
            <div
              className={style.sliderArrowBkgd}
              role="button"
              tabIndex={0}
              aria-label="Previous review"
              onClick={() => slider.current.slickPrev()}
              onKeyDown={() => slider.current.slickPrev()}>
              <FontAwesomeIcon icon={faChevronLeft} size="2x" color="#c9c9c9" />
            </div>
          )}

          <Slider ref={slider} {...settings}>
            {reviews.map((review, i) => {
              const {
                author_name: author,
                text,
                rating: reviewRating,
                relative_time_description: when,
              } = review

              const sliderText = text.length > 295 ? text.slice(0, 295) : null

              const ratingStyles = {
                1: style.rated1,
                2: style.rated2,
                3: style.rated3,
                4: style.rated4,
                5: style.rated5,
              }

              const key = `review-${author}-${when}`
                .replace(/ /g, "-")
                .toLowerCase()

              return (
                <div key={key}>
                  <div>
                    <div>
                      <div className="font-weight-bold">{author}</div>
                      <div className="d-flex align-items-center">
                        <span
                          className={ratingStyles[reviewRating]}
                          aria-label={`${process.env.ORG_OFFICIAL_NAME} is rated ${rating} out of 5 on Google Reviews.`}
                        />{" "}
                        <span className="mt-1 ml-1 text-greyed">{when}</span>
                      </div>
                    </div>
                    <div className="text-greyed">
                      <p className="py-3">
                        {sliderText ? (
                          <>
                            <span>{sliderText}… </span>
                            <ToggleModal
                              toggle={show => (
                                <a
                                  className="text-decoration text-greyed"
                                  role="button"
                                  onClick={show}>
                                  Read more
                                </a>
                              )}
                              content={hide => {
                                return (
                                  <ModalPortal
                                    hide={hide}
                                    size="xl"
                                    centered={true}>
                                    <div className="px-5 pb-4">
                                      <div className="mb-5">
                                        <div className="font-weight-bold">
                                          {author}
                                        </div>
                                        <div className="d-flex align-items-center">
                                          <span
                                            className={`${ratingStyles[reviewRating]} ${style.modalStars}`}
                                            aria-label={`${process.env.ORG_OFFICIAL_NAME} is rated ${rating} out of 5 on Google Reviews.`}
                                          />{" "}
                                          <span className="mt-1 ml-1 text-greyed">
                                            {when}
                                          </span>
                                        </div>
                                      </div>

                                      <div>
                                        {text.split("\n").map((line, index) => {
                                          return <p key={index}>{line}</p>
                                        })}
                                      </div>

                                      <GoogleInfo className="mt-5" />
                                    </div>
                                  </ModalPortal>
                                )
                              }}
                            />
                          </>
                        ) : (
                          text
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </Slider>

          {reviews.length > slidesToShow && (
            <div
              className={`${style.sliderArrowBkgd} ${style.nextArrow}`}
              role="button"
              tabIndex={0}
              aria-label="Next review"
              onClick={() => slider.current.slickNext()}
              onKeyDown={() => slider.current.slickNext()}>
              <FontAwesomeIcon
                icon={faChevronRight}
                size="2x"
                color="#c9c9c9"
              />
            </div>
          )}

          <GoogleInfo />
        </div>
      </div>
      <div className={style.marginHack} />
    </section>
  )
}

export { GoogleReviewSlider }

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState, useEffect } from "react"
import { Row, Col, Container, Button, Card, Form, Media } from "react-bootstrap"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

import { submitCoupon } from "../api/coupons"
import Layout from "../components/Layout"
import BasketItem from "../components/BasketUI/BasketItem"
import { GoogleReviewSlider } from "../components/GoogleReviewSlider"
import HelpBlock from "../components/UI/HelpBlock/HelpBlock"
import OrderSummary, { MobileOrderTotal } from "../components/BasketUI/OrderSummary"
import ClickAndCollectIcon from "../images/bp-store.inline.svg"
import LorryIcon from "../images/bp-lorry.inline.svg"
import Klarna from "../images/klarna-logo.inline.svg"
import NextDayIcon from "../images/bp-stopwatch.inline.svg"
import Bag from "../images/icon-cart-bag-white.inline.svg"
import { basketActions, basketSelectors } from "../state/basket"
import { createDisplayPrice } from "../utils/productsHelper"
import * as style from "./basket.module.scss"

const clearStyle = {
  clear: "both",
}

const Basket = ({
  basketItems,
  basketTotal,
  basketTotalWithDiscount,
  basketItemsCount,
  couponCodeFromStore,
  submitCoupon,
  removeCouponCode,
  isValidCoupon,
  extraFees,
  removeShippingFee,
  calculateShippingFee,
}) => {
  const [couponCode, setCouponCode] = useState(null)
  const [alreadySubmittedCoupon, setCouponSubmission] = useState(false)
  const isKlarnaAvailable = Boolean(Number(process.env.IS_KLARNA_ENABLED))
  const showApplePayLogo = Boolean(Number(process.env.SHOW_APPLE_PAY_LOGO))
  useEffect(() => {
    removeShippingFee()
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    submitCoupon(couponCode)
    setCouponSubmission(true)
  }

  return (
    <>
      <Layout
        staticSeo={{
          seoTitle: "Basket page",
          seoDescription: "Ready for checkout",
        }}
        location={{ pathname: "basket" }}>
        <section className={style.section}>
          <div className="container">
            <h1 className={style.pageTitle}>YOUR BASKET</h1>
            <div>
              <Container className="p-0">
                <MobileOrderTotal
                  orderStage="basket"
                  basketTotal={basketTotal}
                  basketTotalWithDiscount={basketTotalWithDiscount}
                />

                <Row>
                  <Col lg={8} className="px-lg-3">
                    <div>
                      {React.Children.toArray(
                        basketItems.map(item => <BasketItem product={item} />)
                      )}
                    </div>
                    <Row className={style.deliveryOptions}>
                      <Col
                        sm={12}
                        md={4}
                        className="d-flex flex-column justify-content-center align-items-center p-3">
                        <LorryIcon width="80px" height="80px" />
                        <p className={style.deliveryText}>
                          {process.env.DELIVERY_MESSAGE}
                        </p>
                      </Col>
                      <div className={style.verticalLineBox}>
                        <div className={style.verticalLine} />
                      </div>

                      <Col
                        sm={12}
                        md={4}
                        className="d-flex flex-column justify-content-center align-items-center p-3">
                        <NextDayIcon width="80px" height="80px" />
                        <p className={style.deliveryText}>
                          {process.env.NEXT_DAY_SHIPPING_MESSAGE}
                        </p>
                      </Col>
                      <div className={style.verticalLineBox}>
                        <div className={style.verticalLine} />
                      </div>
                      <Col
                        sm={12}
                        md={4}
                        className="d-flex flex-column justify-content-center align-items-center p-3">
                        <ClickAndCollectIcon width="80px" height="80px" />
                        <p className={style.deliveryText}>
                          {process.env.FREE_CLICK_AND_COLLECT}
                        </p>
                      </Col>
                    </Row>
                    <div className="d-none d-lg-block">
                      <GoogleReviewSlider />
                    </div>
                  </Col>
                  <Col lg={{ span: 4, order: 2 }}>
                    <OrderSummary
                      orderStage="basket"
                      basketItems={basketItems}
                      basketItemsCount={basketItemsCount}
                      basketTotal={basketTotal}
                      extraFees={extraFees || null}
                      shippingFee={calculateShippingFee}
                      couponCodeFromStore={couponCodeFromStore}
                      basketTotalWithDiscount={basketTotalWithDiscount}
                      removeCouponCode={removeCouponCode}
                      button={{
                        to: "/basket-delivery-options/",
                        label: "Proceed To Checkout",
                      }}
                    />

                    <Card className="mt-3 mb-5 px-2" body>
                      <div className="d-flex justify-content-between align-items-center w-100 mb-3 ">
                        <h4 className={style.promocodeLabel}>
                          Got a promo code ?
                        </h4>
                        <StaticImage
                          src="../images/arrow-dropdown.png"
                          alt="Down arrow"
                          width={16}
                          height={16}
                          placeholder="none"
                        />
                      </div>
                      <Form id="topForm" noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasic">
                          <Form.Control
                            type="text"
                            placeholder="e.g. 785473"
                            className={style.smallerInput}
                            onChange={e => setCouponCode(e.target.value)}
                            aria-describedby="validationBlock"
                          />
                          {isValidCoupon !== undefined &&
                          !isValidCoupon &&
                          alreadySubmittedCoupon ? (
                            <Form.Text id="validationBlock" muted>
                              Coupon code is not valid
                            </Form.Text>
                          ) : null}
                          {isValidCoupon && alreadySubmittedCoupon ? (
                            <Form.Text id="validationBlock" muted>
                              Coupon code has been successfully applied
                            </Form.Text>
                          ) : null}
                          <Button
                            variant="primary"
                            type="submit"
                            block
                            disabled={
                              isValidCoupon !== undefined && isValidCoupon
                            }>
                            Submit
                          </Button>
                        </Form.Group>
                      </Form>
                    </Card>
                    <Card className="mt-3 mb-5 px-2" body>
                      <h3 className={`${style.pamentOptionsTitle}`}>
                        Secure Payments
                      </h3>
                      <div className={`${style.cards} border-0 `}>
                        <div className={`d-flex ${style.verticalLine2}`}>
                          <div className="p-1 d-flex justify-content-center align-items-center">
                            <StaticImage
                              src="../images/mastercard-logo-mastercard-logo-png.png"
                              alt="payment logo"
                              placeholder="none"
                              objectFit="contain"
                            />
                          </div>
                          <div className="p-1 d-flex justify-content-center align-items-center">
                            <StaticImage
                              src="../images/nowe-logo-visa.png"
                              alt="payment logo"
                              placeholder="none"
                              objectFit="contain"
                            />
                          </div>
                          {showApplePayLogo && (
                            <div className="p-1 d-flex justify-content-center align-items-center">
                              <StaticImage
                                src="../images/apple_pay_logo.png"
                                alt="payment logo"
                                placeholder="none"
                                objectFit="contain"
                              />
                            </div>
                          )}
                          <div className="p-1 d-flex justify-content-center align-items-center">
                            <StaticImage
                              src="../images/google-pay-mark.png"
                              alt="payment logo"
                              placeholder="none"
                              objectFit="contain"
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <small
                            className={`fw-small text-right ${style.stripeText}`}>
                            <span>Safe and secure, SSL Encrypted </span>
                            <br /> Powered by{" "}
                          </small>
                          <div className={`d-flex ${style.stripeImage}`}>
                            <StaticImage
                              src="../images/stripe_logo_.png"
                              alt="payment logo"
                              placeholder="none"
                              objectFit="contain"
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                    {isKlarnaAvailable && (
                      <div className={`${style.klarna} pl-4 pt-3 mb-4`}>
                        <Media>
                          <span className="pr-2" aria-label="Klarna logo">
                            <Klarna />
                          </span>
                          <p>Pay Later or Pay in 3</p>
                        </Media>
                      </div>
                    )}
                    <HelpBlock />
                  </Col>
                </Row>

                {/* <!-- Mobile Review position */}
                <div className="d-lg-none">
                  <GoogleReviewSlider />
                </div>
                {/* Mobile Review position --> */}
              </Container>
            </div>
          </div>
        </section>

        <div style={clearStyle} />
      </Layout>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  basketItems: basketSelectors.getItems,
  basketItemsCount: basketSelectors.getTotalItems,
  basketTotal: basketSelectors.getBasketTotal,
  basketTotalWithDiscount: basketSelectors.getBasketTotalWithDiscount,
  couponCodeFromStore: basketSelectors.getCouponCode,
  isValidCoupon: basketSelectors.isCouponCodeValid,
  extraFees: basketSelectors.getExtraFee,
  calculateShippingFee: basketSelectors.calculateShippingFee,
})

const mapDispatchToProps = {
  setItemCount: basketActions.setItemCount,
  resetBasket: basketActions.resetBasket,
  submitCoupon,
  removeCouponCode: basketActions.removeCouponCode,
  removeShippingFee: basketActions.removeShippingFee,
}
export default connect(mapStateToProps, mapDispatchToProps)(Basket)

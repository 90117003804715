import React from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { basketActions, basketSelectors } from "../../state/basket"

import { Row, Col, ButtonGroup, Card } from "react-bootstrap"

import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as style from "./BasketItem.module.scss"
import { BeforeAddToBasketNotification } from "../../templates/ProductUI/BeforeAddToBasketNotification"
import { rawPrice } from "../../utils/pricesHelper"

import BasketItemImage from "./ProductDescription/BasketItemImage"
import BasketItemAttributes from "./ProductDescription/BasketItemAttributes"

const BasketItem = ({
  basket,
  product,
  plusItemCount,
  minusItemCount,
  setItemCount,
}) => {
  const { count, item } = product || {}
  const {
    variantData,
    genericProductData,
    colour,
    name,
    size,
    featuredImage,
  } = item || {}

  const { id, price } = {...item, ...variantData }
  const  beforeAddToBasketNotification = genericProductData?.beforeAddToBasketNotification || item?.beforeAddToBasketNotification

  let extraFee = beforeAddToBasketNotification?.extraFee?.feeValue

  let itemTotalWithExtraCharge;
  if(extraFee && extraFee > 0) {
    itemTotalWithExtraCharge = (count * Number(rawPrice(price)) + extraFee).toLocaleString()
  }

  let itemTotal = (count * rawPrice(price)).toLocaleString()

  const QuantityCounter = () => {
    const stock = basket
      .filter(item => item.itemId === id)
      .reduce((accumulator, item) => accumulator + item.updatedStock, 0)

    return (
      <ButtonGroup aria-label="Item quantity group">
        <div className={style.quantityButtons}>
          <a
            className={style.quantityButtonMinus}
            onClick={() => minusItemCount(product.item)}>
            -
          </a>{" "}
          <span className="secondary">{count}</span>
          <a
            className={
              stock
                ? style.quantityButtonPlus
                : style.quantityButtonPlusDisabled
            }
            onClick={() => plusItemCount(product.item)}>
            +
          </a>
        </div>
      </ButtonGroup>
    )
  }

  const RemoveItem = () => (
    <a
      className={style.quantityButtonMinus}
      onClick={() => setItemCount(id, 0)}>
      <span className={style.removeIcon}>
        <FontAwesomeIcon
          icon={faTrashAlt}
          style={{ width: "16px" }}
          color="black"
        />
      </span>
    </a>
  )
  return (
    <Row className="m-0 p-0 mb-2">
      <Card
        className="mb-0 border-bottom-0 p-3 d-flex flex-lg-row mt-0"
        key={id}>
        <Col lg={5} className="p-0">
            <BasketItemImage featuredImage={featuredImage} />
        </Col>
        <Col lg={7} className="px-0 p-lg-3">
          <RemoveItem />
          <BasketItemAttributes
            name={name}
            colour={colour}
            size={size}
            price={price}
            product={product}
            extraFee={beforeAddToBasketNotification?.extraFee}
          />
          <div className={style.quantityWrapper}>
            <p className="mr-4 pb-2 secondary h5">
              QUANTITY:
              <span className="ml-2 text-uppercase gray-600">
                <QuantityCounter />
              </span>
            </p>
          </div>

          <div className={style.totalWrapper}>
            <p className="h4 gray-600 text-uppercase">
              TOTAL
              {extraFee && extraFee > 0
                ?<span className="ml-2 h3 secondary" data-total={itemTotalWithExtraCharge}>{"£" + itemTotalWithExtraCharge}</span>
                :<span className="ml-2 h3 secondary" data-total={itemTotal}>{"£" + itemTotal}</span>
              }

            </p>
          </div>
        </Col>
      </Card>
      <div className="w-100 bg-white pb-0">
        <BeforeAddToBasketNotification product={product.item} />
      </div>
      <hr />
    </Row>
  )
}

const mapStateToProps = createStructuredSelector({
  basket: basketSelectors.getItems,
})

const mapDispatchToProps = {
  plusItemCount: basketActions.plusItemCount,
  minusItemCount: basketActions.minusItemCount,
  setItemCount: basketActions.setItemCount,
}

export default connect(mapStateToProps, mapDispatchToProps)(BasketItem)

import React from "react"
import ExclamationIcon from "../../images/icon-circle-exclamation.inline.svg";
import {Card, Row, Col, Container} from "react-bootstrap"
import * as style from "./BeforeAddToBasketNotification.module.scss"
import {
    BACKORDER_MESSAGE,
    GENERIC_MESSAGE_FOR_ALL_PRODUCTS,
    VARIANT_MESSAGE,
    BRAND_BACKORDER_MESSAGE,
    GENERIC_BRAND_MESSAGE_FOR_ALL_PRODUCTS
} from "../../utils/constants";

export const BeforeAddToBasketNotification = ({product}) => {

    let backorderMessage = null;
    let notification = null;
    let skuSpecificMessage = null;
    let stockStatus = product.stockStatus ? product.stockStatus : product.genericProductData.stockStatus
    let productType = product.nodeType
    let beforeAddToBasketNotification = null;
    let genericMessage = null;
    let brandGenericMessage

    if(productType === 'VariableProduct' && product.variantData) {
        if (product.variantData.productNotifications) {
            skuSpecificMessage = product.variantData.productNotifications.VARIANT_MESSAGE
            backorderMessage =  product.variantData.productNotifications.BACKORDER_MESSAGE ? product.variantData.productNotifications.BACKORDER_MESSAGE : product.genericProductData.brandNotifications ? product.genericProductData.brandNotifications.BRAND_BACKORDER_MESSAGE : null
            genericMessage = product.variantData.productNotifications.GENERIC_MESSAGE_FOR_ALL_PRODUCTS
        }

        brandGenericMessage = product.genericProductData.brandNotifications ? product.genericProductData.brandNotifications.GENERIC_BRAND_MESSAGE_FOR_ALL_PRODUCTS : null

        notification = genericMessage || brandGenericMessage

        if(skuSpecificMessage && skuSpecificMessage.length > 0) {
            notification = skuSpecificMessage
        }

        if( (beforeAddToBasketNotification && skuSpecificMessage ) || backorderMessage) {
          if(stockStatus === 'ON_BACKORDER') {
            notification = backorderMessage
          }
        }
    } else if(productType === 'SimpleProduct') {
        if (product.productNotifications) {
            backorderMessage = product.productNotifications.BACKORDER_MESSAGE || product.brandNotifications ? product.brandNotifications.BRAND_BACKORDER_MESSAGE : null
            genericMessage = product.productNotifications.GENERIC_MESSAGE_FOR_ALL_PRODUCTS
        }
        brandGenericMessage = product.brandNotifications && product.isUsed === false ? product.brandNotifications.GENERIC_BRAND_MESSAGE_FOR_ALL_PRODUCTS : null

        notification = genericMessage || brandGenericMessage

        if(backorderMessage) {
            if(stockStatus === 'ON_BACKORDER') {
                notification = backorderMessage
            }
        }

    } else {
      console.error('Error in BeforeAddToBasketNotification.js')
    }

    if (notification !== undefined && notification !== null && notification !== '') {
      return (
          <Card className={`mb-2 d-flex align-items-center justify-content-center text-center ${style.stockNoticationBox}`}>
              <Container>
                  <Row className="py-1">
                      <Col>
                          <span className="mr-3" aria-label="Exclamation mark">
                              <ExclamationIcon />
                          </span>
                          {notification}
                      </Col>
                  </Row>
              </Container>
          </Card>
      )
  }
  return <></>;

}

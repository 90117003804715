import axios from 'axios';
import { addCouponCode, submitCouponCode, invalidCouponCode } from '../state/basket/actions';

const COUPONS_API_URL = `/api/v1/coupons`

const config = {
  baseURL: `${process.env.STRIPE_SVC_BACKEND_BASE_URL}`,
  auth: {
    username: `${process.env.STRIPE_SVC_BACKEND_USERNAME}`,
    password: `${process.env.STRIPE_SVC_BACKEND_PASSWORD}`
  },
};

export const submitCoupon = (couponCode) => {
  return function(dispatch) {
    dispatch(submitCouponCode())
    return axios.get(
        `${COUPONS_API_URL}/${couponCode}`,
        config
    ).then(res => {
      console.log(res)
      if (res.status === 200) {
        return res.data
      } else if (res.status >= 400) {
        // Simple error handling, let's update this when we know what the endpoint will look like.
        // Also returning an invalid coupon object, so the current reducer will handle it, and UI will update
        // We can make a specific UI for this case perhaps
        return ({ valid: false})
      }
    })
    .then(res => {
      if (res && res.amount !== undefined) {
        res.valid = true
        dispatch(addCouponCode(res))
      } else {
        dispatch(invalidCouponCode(res))
      }
    }).catch(err => console.log(err))
  }
};

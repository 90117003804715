// extracted by mini-css-extract-plugin
export var bgGrey100 = "BasketItem-module--bg-grey-100--0e8f9";
export var bgGrey150 = "BasketItem-module--bg-grey-150--9f934";
export var bgGrey200 = "BasketItem-module--bg-grey-200--72c62";
export var bgGrey300 = "BasketItem-module--bg-grey-300--0e533";
export var bgGrey400 = "BasketItem-module--bg-grey-400--c12ac";
export var bgGrey500 = "BasketItem-module--bg-grey-500--0441c";
export var bgGrey600 = "BasketItem-module--bg-grey-600--b4764";
export var bgGrey700 = "BasketItem-module--bg-grey-700--0de32";
export var bgGrey800 = "BasketItem-module--bg-grey-800--32a23";
export var bgGrey900 = "BasketItem-module--bg-grey-900--fd17f";
export var grayLine = "BasketItem-module--grayLine--0f36c";
export var itemDetailsWrapper = "BasketItem-module--itemDetailsWrapper--e332b";
export var itemImg = "BasketItem-module--itemImg--a4d1e";
export var itemImgWrapper = "BasketItem-module--itemImgWrapper--787ad";
export var productName = "BasketItem-module--productName--e9124";
export var quantityButtonMinus = "BasketItem-module--quantityButtonMinus--a9055";
export var quantityButtonPlus = "BasketItem-module--quantityButtonPlus--50d38";
export var quantityButtonPlusDisabled = "BasketItem-module--quantityButtonPlusDisabled--fa59f";
export var quantityButtons = "BasketItem-module--quantityButtons--24412";
export var quantityWrapper = "BasketItem-module--quantityWrapper--8998f";
export var removeIcon = "BasketItem-module--removeIcon--8efb4";
export var removeWrapper = "BasketItem-module--removeWrapper--405ce";
export var textGrey100 = "BasketItem-module--text-grey-100--33009";
export var textGrey150 = "BasketItem-module--text-grey-150--8f8a5";
export var textGrey200 = "BasketItem-module--text-grey-200--9e11d";
export var textGrey300 = "BasketItem-module--text-grey-300--4717c";
export var textGrey400 = "BasketItem-module--text-grey-400--61f90";
export var textGrey500 = "BasketItem-module--text-grey-500--1958d";
export var textGrey600 = "BasketItem-module--text-grey-600--c840d";
export var textGrey700 = "BasketItem-module--text-grey-700--98cf2";
export var textGrey800 = "BasketItem-module--text-grey-800--a96bb";
export var textGrey900 = "BasketItem-module--text-grey-900--275a7";
export var totalWrapper = "BasketItem-module--totalWrapper--264e7";
export var totalWrapperMobile = "BasketItem-module--totalWrapperMobile--ca817";